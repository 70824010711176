import History from '../../../components/history/history';
import { OuterWrapper } from './history.style';

interface HistoryProps {
  smallTitle?: string;
  title?: string;
  description?: string;
  button?: any;
  image?: any;
  backgroundColor?: string;
  titleColor?: string;
  smallTitleColor?: string;
  descriptionColor?: string;
  cornerImage?: any;
}

const HistorySection: React.FC<any> = ({
  data,
  isPrerenderRequest,
}: {
  data: HistoryProps;
  isPrerenderRequest: boolean;
}) => {
  return (
    <OuterWrapper backgroundColor={data?.backgroundColor}>
      <History data={{ ...data, isPrerenderRequest }}></History>
    </OuterWrapper>
  );
};

export default HistorySection;
