import { styled } from '@mui/material';

export const HistoryWrapper = styled('div')(({ theme }) => ({
  maxWidth: theme.spacings.unit(490),
  height: '100%',
  display: 'flex',
  flexDirection: 'row',
  padding: `${theme.spacings.unit(24)}`,
  gap: `${theme.spacings.unit(24)}`,
  alignSelf: 'stretch',
  margin: '0 auto',
  [theme.breakpointsMediaQuery.down('md')]: {
    flexDirection: 'column',
    gap: `${theme.spacings.unit(4)}`,
    padding: `${theme.spacings.unit(14)} 0`,
  },
  [theme.breakpointsMediaQuery.up('md')]: {
    paddingLeft: theme.spacings.unit(26),
    paddingRight: theme.spacings.unit(26),
  },
  [theme.breakpointsMediaQuery.up('xl')]: {
    maxWidth: theme.spacings.unit(1960 / 4),
    paddingLeft: theme.spacings.unit(20 / 4),
    paddingRight: theme.spacings.unit(20 / 4),
  },
}));

export const HistoryLeft = styled('div')(({ theme }) => ({
  width: '52%',
  height: `${theme.spacings.unit(115)}`,
  position: 'relative',
  [theme.breakpointsMediaQuery.down('md')]: {
    width: '100%',
    height: `${theme.spacings.unit(50)}`,
  },
}));

export const HistoryRight = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '48%',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpointsMediaQuery.down('md')]: {
    width: '100%',
    padding: `${theme.spacings.unit(2)} ${theme.spacings.unit(
      9
    )} ${theme.spacings.unit(2)} ${theme.spacings.unit(9)}`,
  },
}));

export const HistoryRightContent = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  height: '73%',
  flexDirection: 'column',
  gap: `${theme.spacings.unit(6)}`,
  [theme.breakpointsMediaQuery.down('md')]: {
    gap: `${theme.spacings.unit(3)}`,
    height: 'fit-content',
  },
}));

export const InfoWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: `${theme.spacings.unit(4)}`,
}));

export const SmallTitleWrapper = styled('div')(({ theme }) => ({
  fontSize: theme.fonts.utility.size(8),
  fontWeight: '800',
  textTransform: 'uppercase',
  lineHeight: '125%',
  position: 'relative',
  color: '#9A7618',
}));

export const TitleWrapper = styled('div')(({ theme }) => ({
  fontSize: theme.fonts.utility.size(16),
  position: 'relative',
  fontWeight: '600',
  lineHeight: '125%',
  color: `${theme.colors.primaryText}`,
}));

export const DescriptionWrapper = styled('div')(({ theme }) => ({
  fontSize: theme.fonts.utility.size(9),
  fontWeight: '400',
  lineHeight: '160%',
  color: `${theme.colors.primaryText}`,
}));

export const ButtonWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  [theme.breakpointsMediaQuery.down('md')]: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  '& > a': {
    borderRadius: theme.spacings.unit(0.25),
    padding: `${theme.spacings.unit(3.75)} ${theme.spacings.unit(5)}`,
    fontSize: theme.fonts.utility.size(7),
    fontWeight: 700,
    lineHeight: '125%',
    letterSpacing: theme.spacings.unit(0.49),
  },
}));

export const CornerImageWrapper = styled('div')(({ theme }) => ({
  position: 'absolute',
  aspectRatio: '160/166.05',
  height: '36%',
  bottom: theme.spacings.unit(6),
  right: theme.spacings.unit(8),
  transform: 'translate(50%, 50%)',
  [theme.breakpointsMediaQuery.down('md')]: {
    height: '50%',
    right: '20%',
    bottom: theme.spacings.unit(4),
  },
}));
