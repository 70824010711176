import Button, { ButtonStyle } from '../button/Button';
import {
  CornerImageWrapper,
  DescriptionWrapper,
  HistoryLeft,
  HistoryRight,
  HistoryRightContent,
  HistoryWrapper,
  InfoWrapper,
  SmallTitleWrapper,
  TitleWrapper,
  ButtonWrapper,
} from './history.style';
import { hexToRgb, rgbDataURL } from 'corporate-utils';
import { getStrapiMedia } from '../../utils/media';
import Markdown from 'markdown-to-jsx';
import { DEFAULT_IMAGE } from '../../models/domain/const';
import ImageWithFallback from '../image-with-fallback/ImageWithFallback';
import Link from 'next/link';

interface HistoryProps {
  data: {
    smallTitle?: string;
    title?: string;
    description?: string;
    button?: any;
    image?: any;
    backgroundColor?: string;
    titleColor?: string;
    smallTitleColor?: string;
    descriptionColor?: string;
    cornerImage?: any;
    isPrerenderRequest: boolean;
  };
}

const History: React.FC<HistoryProps> = ({ data }) => {
  const {
    smallTitle,
    title,
    description,
    button,
    image,
    cornerImage,
    isPrerenderRequest,
  } = data || {};

  return (
    <HistoryWrapper>
      <HistoryLeft>
        {image && (
          <ImageWithFallback
            layout={'fill'}
            objectFit="cover"
            placeholder="blur"
            blurDataURL={rgbDataURL(
              hexToRgb('ffffff')?.[0],
              hexToRgb('ffffff')?.[1],
              hexToRgb('ffffff')?.[2]
            )}
            src={getStrapiMedia(image?.data?.attributes?.url ?? '')}
            alt={image?.data?.attributes?.alternativeText || ''}
            fallbackSrc={DEFAULT_IMAGE}
            isPrerenderRequest={isPrerenderRequest}
          />
        )}
        <CornerImageWrapper>
          {cornerImage && (
            <ImageWithFallback
              layout={'fill'}
              width={100}
              height={100}
              objectFit="cover"
              placeholder="blur"
              blurDataURL={rgbDataURL(
                hexToRgb('ffffff')?.[0],
                hexToRgb('ffffff')?.[1],
                hexToRgb('ffffff')?.[2]
              )}
              src={getStrapiMedia(cornerImage?.data?.attributes?.url ?? '')}
              alt={cornerImage?.data?.attributes?.alternativeText || ''}
              fallbackSrc={DEFAULT_IMAGE}
              isPrerenderRequest={isPrerenderRequest}
            />
          )}
        </CornerImageWrapper>
      </HistoryLeft>
      <HistoryRight>
        <HistoryRightContent>
          <SmallTitleWrapper>
            {smallTitle && <Markdown>{smallTitle}</Markdown>}
          </SmallTitleWrapper>
          <InfoWrapper>
            <TitleWrapper>{title && <Markdown>{title}</Markdown>}</TitleWrapper>
            <DescriptionWrapper>
              {description && <Markdown>{description}</Markdown>}
            </DescriptionWrapper>
          </InfoWrapper>
          <ButtonWrapper>
            <Link href={button.url} passHref>
              <Button
                text={button?.text}
                styleBtn={ButtonStyle?.primary}
                asA={true}
              />
            </Link>
          </ButtonWrapper>
        </HistoryRightContent>
      </HistoryRight>
    </HistoryWrapper>
  );
};

export default History;
